<template>
  <div>
      <annuaire-courtier-vue></annuaire-courtier-vue>
  </div>
</template>

<script>
import AnnuaireCourtierVue from './AnnuaireCourtier.vue'



export default {
  components: {
    AnnuaireCourtierVue
  },
  directives: {

  },
  data() {
    return {
    }
  },
  computed: {
  },
  created() {},
  methods: {}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
