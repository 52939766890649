<template>
  <div>
    <!-- Title page -->
    <div class="mx-auto mb-2">
      <b-row class="justify-content-between align-items-center">
        <b-col md="4">
          <h1 class="text-primary font-weight-bolder text-uppercase ml-1 my-0">Annuaire des courtiers</h1>
        </b-col>
      </b-row>
    </div>

    <b-overlay :show="showLoading" no-wrap />
    <b-card class="annuaire-table" title="FILTRES">
      <div class="custom-search">
          <b-row>
            <b-col md="3" style="margin-top:8px">
              <b-form-group label="Dénomination commerciale" label-for="denomination">
                    <b-form-input
                      v-model="selectedDenomination"
                      id="basic-recherche"
                      class="d-inline-block"
                      type="text"
                      placeholder="Dénomination commerciale"
                      @keyup="searchFilter"
                    />
              </b-form-group>
            </b-col>
            <b-col md="3" style="margin-top:8px">
              <b-form-group label="Dirigeant" label-for="Dirigeant">
                <b-form-input
                      v-model="selectedCourtier"
                      id="basic-recherche"
                      class="d-inline-block"
                      type="text"
                      placeholder="Dirigeant"
                      @keyup="searchFilter"
                />
              </b-form-group>
            </b-col>
            <b-col md="3" style="margin-top:8px">
              <b-form-group label="Département" label-for="filtre-departement">
                <b-form-select id="filtre-departement" v-model="selectedDepartement" @change="searchFilter" placeholder="Département">
                  <b-form-select-option v-for="(departement, index) in listDepartements" :key="index"
                                        :value="departement.code_departement">{{ departement.code_departement + ' - ' + departement.nom_departement }}
                </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Recherche">
                <b-input-group>
                  <b-form-input
                      v-model="recherche"
                      :lazy="true"
                      id="basic-recherche"
                      class="d-inline-block"
                      type="text"
                      placeholder="Recherche"
                      @keyup.enter="searchInput"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                        icon="SearchIcon"
                        class="cursor-pointer"
                        @click="searchInput"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="1" style="margin-top:20px">
              <b-button v-b-tooltip.hover.top="'Tout afficher'"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="btn-icon "
                        style="margin-top:8px"
                        variant="primary"
                        @click="clearAllFilter"
              >
                <feather-icon icon="RefreshCwIcon"/>
              </b-button>
            </b-col>
          </b-row>
      </div>
    </b-card>
    <b-card>
    <vue-good-table
    class="custom-table-bottom"
    v-if="can('List modeles')"
    :columns="metierColumns"
    :rows="rowsMetier"
    :rtl="direction"
    style-class="vgt-table condensed"
    :sort-options="{
      enabled: true,
    }"
    :search-options="{
      enabled: false,
      externalQuery: searchTerm
    }"
    :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: pageLength,
        rowsPerPageLabel: '',
        dropdownAllowAll: false,
        perPageDropdownEnabled: false,
        nextLabel: 'Suivant',
        prevLabel: 'Précédent',
        ofLabel: 'de',
        infoFn: params => ``
    }"
    @on-selected-rows-change="selectionChanged"
    :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'lignes sélectionnées',
        clearSelectionText: 'Effacer la sélection',
        disableSelectInfo: false,
        selectAllByGroup: false
      }"
    >
    <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field == 'dirigeant'">
            {{ props.row.dirigeant }}
        </span>
        <span v-else-if="props.column.field == 'email'">
            {{ props.row.email }}
        </span>
        <span v-else-if="props.column.field == 'ville'">
            {{ props.row.ville }}
        </span>
        <span v-else-if="props.column.field == 'code_postal'">
            {{ props.row.code_postal }}
        </span>
        <span v-else-if="props.column.field === 'actions'">
        <span>
            <feather-icon icon="DownloadCloudIcon" size="20" class="mr-50" />
        </span>
        </span>
        <span v-else>
            {{ props.formattedRow[props.column.field] }}
        </span>
    </template>
    <!-- pagination -->
    <template slot="pagination-bottom" slot-scope="props">
        <b-row class="mt-2 align-items-center">
            <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                    Affichage 1 à
                </span>
                <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <!-- <span class="text-nowrap"> de {{ props.total }} entrées </span> -->
                </div>
            </b-col>
        </b-row>
        <b-row class="mt-2 align-items-center">
          <b-col md="4">
            <b-input-group>
              <b-form-select
                  v-model="selected_action"
                  :options="options"
              />
              <b-input-group-append>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="action_masse(selected_action)"
                >
                  <span>Valider </span>
                  <feather-icon
                      class="mr-50"
                      icon="ArrowRightIcon"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        <b-col md="8">
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
            <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
            </b-pagination>
        </b-col>
        </b-row>
    </template>
    <div slot="emptystate" class="text-center align-middle">
        <span>Aucune donnée disponible dans le tableau</span>
    </div>
    </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import{
        BPagination, VBToggle, BRow, BCol, BDropdown, BDropdownItem, BButton, BCard, BCardText, BCardHeader, BMedia, 
        BImg, BOverlay, BFormSelect,  BFormGroup,
        BFormInput,
        VBTooltip,
        BFormSelectOption,
        BInputGroup, BInputGroupAppend
} 
from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import Data from "@/shared/constants/prospect";
import { VueGoodTable } from 'vue-good-table'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VBToggle,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    BCardHeader,
    VBTooltip,
    BMedia,
    BImg,
    BOverlay,
    VueGoodTable,
    BPagination,
    BFormSelect,
    ToastificationContentVue,
    BFormInput,
    BFormGroup,
    BFormSelectOption,
    BInputGroup,
    BInputGroupAppend

  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      currentUser: JSON.parse(localStorage.getItem('userData')),
      isBoiteSync: false,
      recherche:null,
      affected: false,
      selectedCourtier:null,
      selectedDepartement: null,
      courtiersOptions:[],
      selectedrows_masse : [],
      selectedDenomination:null,
      denominationOptions:[],
      showLoading: false,
      pageLength: 3,
      dir: false,
      renderComponent: true,
      modalAction: 'Nouveau',
      rowsMetier: [],
      rowsMetierGlobal: [],
      searchTerm: '',
      metierColumns: [
        { label: 'Dénomination commerciale', field: 'denomination_commercial', thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
        { label: 'N° ORIAS', field: 'numero_orias', thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
        { label: 'Dirigeant', field:'dirigeant', thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
        { label: 'Mail', field: 'email', thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
        { label: 'Ville', field: 'ville',sortable: true, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
        { label: 'Département', field: 'code_postal', thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
        { label: 'Bio', field: 'bio', thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
      ],
      options: [
        {
          value: null,
          text: 'Choisissez'
        },
        {
          value: 'Envoyer',
          text: 'Envoyer un mail'
        },
      ],
      selected_action: null,
      listDepartements: Data["DEPARTEMENTS"]
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    }
  },
  watch: {
    rowsMetierGlobal: {
      immediate: true,
      deep: true,
      handler(val) {
        if(val.length > 0 && this.selectedDepartement)
          this.searchFilter()
      }
    }
  },
  created() {
      this.getcourtierData();
  },
  mounted() {
    this.checkSyncBoiteEmail()
    this.initialDepartement()
  },
  methods: {
    initialDepartement() {
      let ville = this.currentUser.courtier_user[0].courtier.personne_morale.moyen_contact.ville
      let departementCabinet = null
      if(ville) {
          departementCabinet = ville.code_postal
          if(departementCabinet) {
            this.selectedDepartement = departementCabinet.substring(0, 2)
          } else this.selectedDepartement = this.listDepartements[0].code_departement
      }
    },
    action_masse(action){
      switch (action) {
        case 'Envoyer':
            if(this.isBoiteSync) {
              let emails = [];
              this.selectedrows_masse.selectedRows.forEach(el => {
                if (el.email) {
                  emails.push(el.email)
                }
              })
              if(emails.length > 0 && emails.length <= 20)
              {
                this.$swal({
                  title: "Souhaitez-vous envoyer les e-mails en tant que :",
                  icon: "question",
                  showCancelButton: true,
                  confirmButtonText: "Envoyer",
                  cancelButtonText: "Annuler",
                  customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                  },
                  input: 'radio',
                  inputValue: 'Cci',
                  inputOptions: {
                    'To': '<span> Destinataire</span>',
                    'Cci': '<span> Copie cachée</span>',
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.value) {
                    this.showEmail(emails, result.value)
                  }
                })
              } else{
                this.messageToast('Le nombre de destinataires ne peux pas dépasser 20 destinataires au maximum', 'Attention', 'warning', 'AlertCircleIcon')
              }
            } else {
              this.messageToast('Il semble que vous n\'avez pas encore synchronisé votre boite', 'Attention', 'warning', 'AlertCircleIcon')
            }
        default:

      }
    },
    showEmail (email, copierCarbon = null) {
      if(this.isBoiteSync) {
          this.$router.push({
            name: 'messageries',
            params: {
              operation: 'send',
              email,
              copierCarbon
            }
          })
      }
    },
    checkSyncBoiteEmail() {
      this.isBoitEmailSync().then(response => {
        if(response)
          this.isBoiteSync = true
        else this.isBoiteSync = false
      })
    },
    selectionChanged(params) {
      this.selectedrows_masse = params
      console.log(this.selectedrows_masse)
    },
    getcourtierData() {
        this.showLoading=true
        this.$http.get('/courtier/getCourtierAnnuaire')
            .then(response => {
                // this.rowsMetier = response.data
                this.rowsMetierGlobal = response.data
                this.showLoading=false
            }).catch(err => {
                console.log(err)
                this.showLoading=false
            });
    },
    forceRerender() {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant
        }
      })
    },
    searchInput(){
      this.showLoading = true
      setTimeout(() => {
        if(this.recherche != null) {
          if(!this.affected) {
            this.searchFilter()
            this.affected = true
          }
          this.searchTerm = this.recherche
        }
        this.showLoading = false
      }, 500);
    },
    clearAllFilter() {
      this.selectedDenomination = null
      this.selectedCourtier = null
      this.searchTerm = null
      this.recherche = null
      this.initialDepartement()
      this.searchFilter()
    },
    searchFilter(){
        let denomination     = this.selectedDenomination
        let departement      = this.selectedDepartement
        let courtier         = this.selectedCourtier
        this.rowsMetier      = this.rowsMetierGlobal
        if(denomination || courtier || departement){
          if(denomination){
           this.rowsMetier = _.filter(this.rowsMetier, function(rows){
                return rows.denomination_commercial.toLowerCase().includes(denomination.toLowerCase())
            });
          }
          if(courtier){
            this.rowsMetier = _.filter(this.rowsMetier, function(rows){
              if(rows.dirigeant)
                return rows.dirigeant.toLowerCase().includes(courtier.toLowerCase());
            });
          }
          if(departement){
            this.rowsMetier = _.filter(this.rowsMetier, function(rows){
              if(rows.ville)
                return rows.code_postal == departement;
            });
          }
        }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
div.custom-table-bottom >div >.vgt-responsive{
    min-height: 0 !important;
}
// .annuaire-table > div >h4.card-title {
//     margin-bottom: 0 !important; 
// }
</style>